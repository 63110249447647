

// COLORS
//headings
$color-blue-dark: #000000;
$color-blue-light: #00A7FA;

//text
$color-grey-dark: #545454;
$color-grey-dark-1: #565656;
$color-grey-light:#707070;
$color-grey-light-bright: #969696;

//
$color-black: #000;


// FONT VARIABLES
$font-primary: 'Montserrat', sans-serif;
$font-display: 'Montserrat', sans-serif;
$font-secondary: 'Montserrat', sans-serif;
$font-light: 'Montserrat', sans-serif;
$font-book: 'Montserrat', sans-serif;



.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}



*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    
    
}

html {
    scroll-behavior: smooth;
    box-sizing: border-box;
    font-size: 56.25%;
   /*  overflow: hidden; */
    overflow-x: hidden;
    scroll-behavior: smooth;
    -webkit-font-smoothing: subpixel-antialiased;
    ::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
  }
    @include respond(small-desktop) { // width < 1200?
      font-size: 50%; //1 rem = 9px, 9/16 = 50%
  }
    @include respond(tab-land-large) { 
    font-size: 47.5%;
    
  }
  @include respond(haha-desktop) {
    font-size: 47%;
  }

  
    
 

}

body {

    color: $color-grey-dark;
    line-height: 1.6;
    font-family: $font-primary; 
}


html,
body {
  height: 100%;
  margin: 0;
  //overflow: auto;
}

//remove onclick outline
*:focus {
  outline: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

img {
  max-width:100%;
}
img:not([src]) {
visibility: hidden;
}
img:-moz-loading {
visibility: hidden;
}

.container {
    position: relative;
    display: flex;
    //grid-template-columns: [full-start] repeat(8, [col-start] minmax(min-content, 1fr) [col-end]) [full-end];
    min-height: 100vh;
        
}

table, tr, td, tr {
  color: black;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

th, td, tr {
  padding: 10px
}

tr > input {
  width: 70px;
}
