.Sexselection {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    //opacity: 0;
    //visibility: hidden;
    transition: all .3s;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(#111, .3);
    }
    &__content {
        @include absCenter;
        padding-bottom: 3rem;
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        box-shadow: 0 2rem 4rem rgba(#111, .2);
        border-radius: 3px;
        overflow: hidden;
        transition: all .5s .2s;
        
        svg {
            width: 10rem;
            fill: #a70707d3;
        }
    }
    &-black {
        width: 100%;
        height: 5rem;
        background-image: linear-gradient(to bottom, #0F2027, #203A43);
    }
    &__textarea {
        padding: 0 2rem;
        text-align: center;
    }
    &--text {
        font-size: 1.8rem;
        font-family: $font-display;

    }
    &__buttons {
        display: flex;
        &-button {
            padding: 2rem 4rem;
            display: inline-block;
            vertical-align: middle;
            margin-top: 2rem;
            font-weight: 700;
            font-size: 1.4rem;
            border: none;
            background-image: linear-gradient(to  bottom, #ED213A, #93291E);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            //border-radius: 5px;
            &:hover {
                transform: scale(1.02) translateY(-2px);
            }
        }
    }
    
    &:target {
        opacity: 1;
        visibility: visible;
    }

    &:target &__content {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

