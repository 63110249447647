
%heading {
    font-family: $font-primary;
    font-weight: 400;
    
}


.heading-1 {
    //@extend %heading;
    font-family: $font-secondary;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    font-size: 2.3rem;
    font-weight: 600;
    @include respond(tab-land-small) { 
        font-size: 1.6rem; 
    }
    @include respond(tab-port-small) {
        text-align: center
    }
    

}

.heading-2 {
    @extend %heading;
    color: $color-blue-dark;
    font-size: 7rem;
}
.heading-mobile {
    text-align: center;
    font-weight: 600;
    font-size: 3rem;
    @include respond(tab-port) {
        font-size: 3rem;
    }
}

.heading-3 {
    @extend %heading;
    color: $color-grey-dark-1;
    font-size: 5rem;
    @include respond(tab-port) { 
        font-size: 3.5rem; 
    }
    @include respond(phone) {
        font-size: 4rem;
    } 
    
}

a,
a:link,
a:visited {
    cursor: pointer;
    position: relative;
    text-decoration: none;
    
    display: block;        
    cursor: pointer;
} 
    


.btn {
    background-color: transparent;
    color: #fff;
    border: none;
    border-radius: 0;
    font-family: $font-primary;
    font-size: 1.4rem;
   
    padding: 2rem 2.5rem;
    cursor: pointer;
    transition: all .2s;
    text-align: center;
    
    &-smaller {
        font-size: 2rem;
        font-weight: 500;
        @include respond(tab-port) {
            padding: 2rem 1rem;
        }
        &:disabled,
        &[disabled] {
            opacity: 0.6;
        }
    }
    
    &-blue {
        color: #fff;
        background: linear-gradient(to right, #b26dfa, #5c4f8c);
         box-shadow: 0 2rem 5rem rgba(#000, .9);
        display: inline-block;
    }
    &-black {
        color: #111;
        display: inline-block;
    }
    &-blueblack {
        margin-top: 3rem;
        padding: 2rem 4rem;
        background-image: linear-gradient(to bottom, #0F2027, #2C5364);  

    }
    
    &-shadow {
        box-shadow: 0 1rem 5rem rgba(#000, .3);
        border-radius: 25px;

        &:hover {
            box-shadow: 0 1rem 5rem rgba(#000, .5);
        }
    }
    &-filter {
        margin-top: 1rem;
        text-transform: none;
        font-size: 1.8rem;
        align-self: center;
        padding: 1.5rem 2rem;
        @include respond(phone) {
            grid-column: 1/-1;
            justify-self: center;
        }
    }
    &-size {
        
        width: 9rem;
        padding: 1rem;
        text-align: center;
        font-size: 2rem;
        font-family: $font-secondary;
        line-height: 1;
        text-transform: uppercase;
        color: $color-grey-light;
        box-shadow: 0 1rem 3rem rgba(#000, .4);
        border-radius: 25px;
        border: solid 2px transparent;
        @include respond(phone) { 
            width: 100%; 
        }
        

    }
    &-start {
        margin-bottom: 2rem;
        font-size: 2rem;
        font-family: $font-display;
        font-weight: 700;
        @include respond(phone) { 
            font-size: 1.6rem;
        }
    }
    
}

.btn-search {
    background-color: transparent;
    color: $color-grey-light;
    border: none;
   // font-family: $font-book;
    font-size: 1.6rem;
    //text-transform: capitalize;
    cursor: pointer;
    transition: all .2s;
    padding: 0.5rem 0.3rem;
    text-align: start;
    border-radius: 7px;
    box-shadow: 0 0 1rem rgba(#000, .2); // change it to 10px if bozuk
    display: inline-block;
    vertical-align: middle;
    //width: 100%;
    @include respond(phone) { 
        text-align: center;
        padding: 0.5rem 1rem;
        height: 4rem !important;
    }
    &-select {
        &__menu {
            margin-top: 0.5rem !important;
            width: 95% !important;
            
        }
        &__indicator,
        &__control {
            width: 100%;
            border: none !important;
            min-height: 0 !important;
            box-shadow: none !important; 
            background-color: transparent !important;
            @include respond(small-desktop) { 
                height: 4rem !important;
                border-radius: 250px;
                padding: 0rem 0rem !important;
                align-items: center;
            }
            @include respond(phone) { 
                height: 4rem !important;
                border-radius: 250px;
                padding: 0rem 0rem !important;
                align-items: center;
            }
        }
    }
    &-detailed {
        z-index: 999999;
        margin-left: auto;
        display: flex;
        font-size: 1.4rem;
        align-items: center;
        width: 20rem;
        svg {
            fill: $color-grey-dark;
            margin-left: 1rem;
            width: 2rem;
        }
        @include respond(phone) { 
            width: 13rem;
            margin-left: auto;
            grid-row: 1 / 2;
            //grid-column: 3 / 5;
            text-align: center;
            justify-items: center;
            justify-content: center;
            
        }
        
    }
 
    &:hover
	 {

        transition: all .3s;
        transform: scale(1.10);
		background-color: #fff;
		
    }
    &:active {
        transform: scale(1.03);
    }
    

}
.div__flex-buttons-small {
    display: grid;
    grid-template-columns: 35rem 35rem;
    grid-column-gap: 4rem;
    justify-content: center;
    align-content: center;
    padding: 2rem;
    @include respond(tab-port-small) { 
        padding: 2rem;
        grid-column-gap: 3rem;
        grid-template-columns: 25rem 25rem;
    }
    @include respond(phone) { 
        padding: 1rem;
        grid-column-gap: 1rem;
        grid-template-columns: minmax(17rem, 1fr) minmax(17rem, 1fr);
    }

}
.div__flex-buttons {
    //padding: 2rem;
    display: grid;
    grid-template-columns: 20rem 20rem;
    grid-column-gap: 4rem;
    justify-content: center;
    align-content: center;
    padding: 2rem;
    @include respond(tab-port-small) { 
        padding: 2rem;
        grid-column-gap: 3rem;
        grid-template-columns: 25rem 25rem;
    }
    @include respond(phone) { 
        padding: 1rem;
        grid-column-gap: 1rem;
        grid-template-columns: minmax(17rem, 1fr) minmax(17rem, 1fr);
    }
    //@media only screen and (max-width: 28.6875em) {
        //width: 60vw;
        //margin-top: 1rem;
        //display: flex;
        //flex-direction: column-reverse;
        //& > * {
            //&:last-child {
                //margin-bottom: 2rem;
            //}
        //}
        
    //}
    
    
 
    &-smaller {
        padding: 3rem;
        display: grid;
        grid-template-columns: 30rem 30rem;
        grid-column-gap: 4rem;
        justify-content: center;
        align-content: center;
        @include respond(phone) { 
            grid-template-columns: 40vw 40vw;
            grid-column-gap: 2rem;
            padding-top: 2rem;
            padding: 1rem;
            
        }
        @media only screen and (max-width: 28.6875em) {
            margin-top: 1rem;
            display: flex;
            flex-direction: column-reverse;
            & > * {
                &:last-child {
                    margin-bottom: 2rem;
                }
            }
            
        }

    }
    
    
    
}

.aciklamafirst {
    font-size: 2rem;
    font-weight: 600;
    color: #663796;
}