.alert {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999 !important;
    transition: all .3s;
    z-index: 99999999999;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(#111, .3);
    }
  

    &__content {
        @include absCenter;
        z-index: 9999999999 !important;
    
        padding: 3rem;
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        //background-color: transparent; 
        box-shadow: 0 2rem 4rem rgba(#111, .2);
        border-radius: 3px;
        overflow: hidden;
        transition: all .5s .2s;
        border-radius: 25px;
        @include respond(tab-port) { 
            width: 90%;
        }

        &-small {

            width: 30%;
        }
        
        svg {
            width: 10rem;
            fill: #5c4f8c;
        }
    }
    &-red {
        width: 100%;
        height: 5rem;
        background-image: linear-gradient(to right bottom, #afe2ff 11.2%, #5c4f8c 100.2%);
    }
    &-black {
        margin-bottom: 3rem; // kalkcak oburune de gelecek 
        width: 100%;
        height: 5rem;
        background-image: linear-gradient(to right bottom, #afe2ff 11.2%, #5c4f8c 100.2%);   
    }
    
    &__textarea {
        padding: 0 2rem;
        text-align: center;
    }
    &__text {
        color: #111;
        font-size: 1.8rem;
        font-family: $font-display;
        

    }
    &__buttons {
        margin-top: 3rem;
        &-buttones {
            button {
                
                padding: 1.6rem 4rem;
            }

        }
    }
    &__button {
        padding: 2rem 4rem;
        display: inline-block;
	    vertical-align: middle;
        margin-top: 2rem;
        font-weight: 700;
        font-size: 1.4rem;
        background-image: l#5c4f8c 1;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        //border-radius: 5px;
        &:hover {
            transform: scale(1.02) translateY(-2px);
        }
    }
    &:target {
        opacity: 1;
        visibility: visible;
    }

    &:target &__content {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    &__textscrollable {
        padding: 3rem;
        background-color: #fcfbfb;
        overflow-y: scroll;
        height: 100%;
        max-height: 40rem;
        color: #111;
        font-size: 1.8rem;
    
        

    }

}
.loading {
    height: 100%; //100vhydi
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999999999;
    transition: all .3s;
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(#111, .3);
    }

  
  

    &__content {
        @include absCenter;
        padding-bottom: 3rem;
        height: 45rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: transparent; 
        border-radius: 3px;
        overflow: hidden;
        transition: all .5s .2s;
        @include respond(tab-port) {
            height: 40rem;
        }
        &-inside {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index:  99;
            border-radius: 25px;
            box-shadow: 0 1rem 5rem rgba(#000, .4);
            background-color: white;
            width: 50rem;
            height: 80%;
            @include respond(tab-port) {
                width: 35rem;
            }
            &-text {
                opacity: 1;
                width: 100%;
                font-size: 2rem;
                transition: all 1s;
            }
            &-texts {
                width: 100%;
                margin-top: 2rem;
                display: flex;
                text-align: center;
                
            }
        }

    }
    
}

#loadinggif {
    @include respond(tab-port) {
        width: 40%;
    }
}

.loginpopup {
    height: 100%; //100vhydi
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999999999;
    transition: all .3s;
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(#111, .3);
    }
    &__content {
        @include absCenter;
        padding-bottom: 3rem;
        height: 45rem;
        width: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 5rem; 
        border-radius: 25px;
        overflow: hidden;
        transition: all .5s .2s;
        @include respond(tab-port) {
            height: 40rem;
        }

    }
    
}
.alert-enter {
    opacity: 0;
    //transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }
  