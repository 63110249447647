.landing {
    padding: 3rem;
    position: absolute;
    width: 100%;
    height: 100%;
    //grid-column: full-start / full-end;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.3;
    @include respond(phone) {
        //justify-content: unset;   
        padding: 0;
    }
    &-step0 {
        transform: translateY(50%);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &-less {
            transform: translateY(35rem);
        }
        &-more {
            transform: translateY(70%);
        }
    }

    
    &-nojustify {
        justify-content: flex-start;
        @include respond(phone) { 
            //align-items: flex-start;
            
        }
    }
    

    &__grid {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: grid;
        justify-content: center;
        align-content: center;
        justify-items: center;
        line-height: 1.3;
     
        @include respond(tab-port) { 
            padding: 3rem;
        }
        @include respond(phone) { 
            align-content: unset;
        }
    }
    &__userbody {
        display: flex;
        margin: 3rem;
        margin-top: 4rem;
        flex-direction: row;
        align-items: flex-start;
        //right left in userbody
        @include respond(tab-port) {
            margin: 3rem;
            margin-top: 2rem;   
        }
        @include respond(tab-port-small) {
            margin: 4rem;
            margin-top: 2rem;   
        }
        @include respond(phone) { 
            margin: 4rem 0;  
        }
        @include respond(phone-small) { 
            margin: 2rem 0;
        };
        
        &-right {
            margin-left: 8rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            @include respond(phone) {
                margin-left: 1rem;
                
            }
        }
        &-left {
            display: grid;
            grid-row-gap: 5rem;
            justify-items: flex-start;
            flex: 1;
            @include respond(tab-port-small) { 
                grid-row-gap: 4rem;
            }
            @include respond(phone) {
                grid-row-gap: 2rem; 
            }
            
        }
        //numbers in body svg
        &-length {
            @include respond(tab-land-large) {
                display: none;

            }
            @include respond(phone){
                display: block;
            }
            &-avatar {
                width: 20rem;
                @include respond(phone) {
                    width: 17.5rem;
                }
     
                
                
            }
            top: 14%;
            left: 43%;
            position: absolute;
            display: block;
            width: 4rem; // maybe change to rem 
            text-align: center;
            font-size: 1.2rem;
            font-family: $font-secondary;
            color: #663796;
            font-weight: 700;
            @include respond(phone) {
                top: 5.8rem;
                left: 43.5%;
            }
            &-2 {
                top: 23%;
                @include respond(phone){
                    top:9.1rem;
                }
            }
            &-3 {
                top: 34.5%;
                @include respond(phone){
                    top: 13.7rem;
                }

            }
            &-4 {
              
  
     
                top: 34%;
                left: 76%;
       
                backface-visibility: hidden;
                -webkit-font-smoothing: subpixel-antialiased;
                @include respond(tab-land-large) {
                    display: none;
                }
                @include respond(phone){
                    display: block;
                }
                @include respond(phone) {
                    top: 11rem;
                    left: 83%;
                  
                }
       
               


            }
            &-5 {
                top: 70%;
                left: 10%;
                @include respond(phone){
                    top: 69%;
                    left: 0%;
                }

            }
            &-6 {
                top: 42.3%;
                @include respond(phone) {
                    top: 41.5%;
                }
                

            }

        }
    }
    &__avatar {
        position: relative;
        &-svg {
            width: 20rem;
            height: 40rem;
            @include respond(phone) {
                height: 35rem;
                
            }
        }
    }
    
    //TEXT
    &__text {
        text-align: center;
        font-size: 3.5rem;
        @include respond(tab-port-small) { 
            font-size: 3rem;
            line-height: 1.6;
        }
        @include respond(phone) { 
            line-height: 1.3;
            font-size: 2rem;
        }
        
        //Landing
        &-1 {
            margin-bottom: 3rem;
            margin-top: 0rem;
        }
        //Step1
        &-2 {
            //margin-top: 3.5rem;
            //margin-bottom: 4rem;
            &--top {
               
              
                @include respond(tab-port-small) { 
                    margin-top: 2rem;
                    padding: 0 1.5rem;
                }
            }
            &--bottom {
                margin-bottom: 4rem;
                @include respond(tab-port-small) { 
                    margin-bottom: 2rem;
                    padding: 0 1.5rem;
                }  
            }
        }
        //GetCompanySize
        &-3 {
            font-size: 3.2rem; 
            font-family: $font-secondary;
            margin-bottom: 1.5rem;
            @include respond(phone) { 
                font-size: 2.6rem;
         
            }
            
        }
        // inside companybox
        &-4 {
            
            font-size: 2.7rem;
            font-family: $font-secondary;
            @media only screen and (max-width: 28.6875em) {
                font-size: 2.4rem;
            }
        }
        &-5 {
            margin-top: 3rem;
            font-size: 3.2rem; 
            font-family: $font-secondary;
            @include respond(phone) { 
                font-size: 2.6rem;
         
            }
            
        }
        
        &-large {
            //margin: 2rem;
            margin-bottom: 2.5rem;
            font-size: 3.3rem;
            font-family: $font-display;
            @include respond(tab-port) { 
                font-size: 3rem;
            }
            @include respond(tab-port-small) { 
                font-size: 2.7rem;
            }
            @include respond(phone) {
                font-size: 2rem;
                text-align: center; 
            }
            @include respond(phone-small) { 
                margin: 0;
                font-size: 1.8rem
            };
            
            
        }
        &-medium-large {
            font-family: $font-secondary;
            font-size: 3.5rem;
            //margin-top: 3rem;
            margin-bottom: 0.5rem;
            text-align: center;
            @include respond(tab-port) { 
                font-size: 3rem;  
            }
            @include respond(tab-port-small) { 
                margin-top: 2rem;
            }
        }
        &-medium {
            font-size: 2.8rem;
            text-align: center;
            @include respond(phone) { 
                font-size: 1.6rem;
            }
            &-1 {
                margin-bottom: 3rem;
                @include respond(tab-port) { 
                    margin-bottom: 1rem;
                }

                
            }
            &-semibold {
                font-family: $font-secondary;
                
            }
            &-romanbody {
                font-family: $font-display;
                color: $color-grey-light-bright;
                @include respond(tab-port) { 
                    font-size: 2.5rem;
                   
                }
            } 
        }
        &-avatar{
            margin-bottom: 2.5rem;
            font-size: 3.3rem;
            font-family: $font-display;
            @include respond(phone) {
                margin-bottom: 2rem;
                font-size: 2rem;
                font-family: $font-secondary;
            }
            
            
        }
        &--input {
            font-family: $font-display;
            font-size: 2.4rem;
            @include respond(phone) {
                font-size: 1.6rem;
                //devam
            }
        }

        
    }
    //components
    &__input {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: 25rem 12.5rem;
        
        justify-items: flex-start;
        align-items: center;
        @include respond(phone) {
            margin: 0;
            grid-template-columns: 15rem;
            grid-row-gap: 2rem;
        }
        
        &--box {
            display: flex;
            position: relative;
            box-shadow: 0 1rem 5rem rgba(#000, .2);
            border-radius: 25px;
            overflow: hidden;
            height: 115%;
            
            &-value {
                background-color: transparent;
                font-size: 1.7rem;
                color: inherit;
                border-radius: 2px;
                border: none;
                margin-left: 2rem;
                width: 35%;
                display: block;
                transition: all .3s;
                &:focus {
                    outline: none;
                    background-color: transparent;
      
                   
                }
                &:active {
                    outline: none;
                    background-color: transparent;

                   
                }
            }
            &-text {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 5rem;
                margin-left: auto;
                border: 2px solid $color-grey-light;
                border-radius: 25px;
                font-size: 1.6rem;
            }

        }
        
    }
    &__button {
        &-toosmall {
            font-size: 2rem;
            margin-top: 3rem;
        }
        &-smaller {
            font-family: $font-secondary;
            font-size: 2.7rem;
            text-transform: capitalize;
        }
        &-small {
            //padding: 2rem 8rem;
            font-family: $font-secondary;
            font-size: 3.5rem;
            text-transform: capitalize;
            @include respond(tab-port-small) { 
                font-size: 2.7rem;
            }
            @include respond(phone) { 
                font-size: 2rem;
            }
        }
        &-wide {
            border: 1px transparent solid;
            padding: 4rem 3rem;
            margin-bottom: 2rem;
            width: 70rem;
            text-align: center;
            font-family: $font-secondary;
            text-transform: initial;
            font-size: 2.4rem;
            @include respond(phone) { 
                width: 90vw;
                font-size: 1.6rem;
                padding: 3rem;
            }
            &-checkbox {
                display: none;
                &:checked+label {
                    transform: scale(0.97);
                    box-shadow: 0 1rem 5rem rgba(#000, .6);
                    border: 1px solid $color-black;
                  }
                  
    
            }
        }
        
    }

    // GET SIZE WITH COMPANIES

    
    &__companylogos {
 
        width: 100%;
        margin: 5rem;
        margin-bottom: 7rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(17.5rem, 1fr));
        grid-template-rows: repeat(auto-fit, minmax(17.5rem, 1fr));
        grid-gap: 1.5rem;
        grid-row-gap: 3rem;
        &-tworows { //there s some bug which does not center the content when we are using csstransition in both step forms(getcompaniessize, getuserbody), there would not be any problem if we had used router but this works.
            grid-template-columns: repeat(5, minmax(17.5rem, 1fr));

        }
        @include respond(tab-port) { 
            grid-gap: 2rem;
            grid-template-columns: repeat(5, minmax(12rem, 15rem));
            grid-template-rows: repeat(2, 15rem);
            justify-content: center;
            margin-bottom: 5rem;
        }
        @include respond(tab-port-small) { 
            grid-gap: 2rem;
            grid-template-columns: repeat(4, minmax(12rem, 15rem));
            grid-template-rows: repeat(3, 15rem);
            justify-content: center;
            margin: 3rem;
        }
        @include respond(phone) { 
            grid-gap: 5rem;
            grid-template-columns: repeat(auto-fit, minmax(10rem, 14rem));
            grid-template-rows: repeat(auto-fit, minmax(10rem, 14rem));
            justify-content: center;
            margin: 2rem 0;
        }
       
    }
    &__companylogo {
        height: 17.5rem;
        width: 17.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        //background:url(./hmlogo.png) no-repeat center;
        //background-size: contain;
        border-radius: 250px;
        transition: all .2s;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @include respond(tab-port) { 
            height: 15rem;
            width: 15rem;
           
        }
        @include respond(phone) { 
            height: 15rem;
            width: 15rem;
        }
        &-checkbox {
            
            display: none;
            &:checked+label {
                
                transform: scale(0.90);
                //box-shadow: 0 1rem 5rem rgba(#000, .8);
                border: rgb(44, 44, 44) 2px solid;
              }
              

        }
        
        
    }
    &__companyblocks {
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(55rem, 1fr));
        margin-top: 2rem;
        
        @include respond(phone) { 
            //padding: 2rem;
            
        }
        @media only screen and (max-width: 28.6875em) {
            grid-template-columns: repeat(auto-fit, minmax(28rem, 39rem));
        }
        

        & > * {
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }

    //GetCompanySize
    &__companyblock {
        display: grid;
        //border: 1px black solid;
        grid-template-columns: 7rem 15rem 1fr;
        transition: all 0.4s;
        grid-column-gap: 3rem;
        @include respond(phone) { 
            grid-column-gap: 2rem;
            grid-template-columns: 0 12.5rem 1fr 0;
        }
        @media only screen and (max-width: 28.6875em) {
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr;
        }
        &--size {
            display: flex;
        }

        
       
        &--remove {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
            display: flex;
            background-color: #5036a6;
            border-radius: 250px;
            width: 5rem;
            height: 5rem;
            color: #fff;
            align-self: center;
            justify-self: center;
            justify-content: center;
            align-items: center;
            transition: all .2s;
            &:hover {
                transform: translateY(-2px) scale(1.10);
                box-shadow: 0 1rem 5rem rgba(#5036a6, .6);
            }
            @include respond(phone) { 
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                align-self: end;
                width: 4rem;
                height: 4rem;
            }
            @media only screen and (max-width: 28.6875em) {
                grid-column: 1 / -1;
                margin-right: 20rem;
                align-self: center;
            }
                
            
            &-line {
                width: 3rem;
                height: 3px;
                background-color: #fff;
                display: inline-block;
                @include respond(phone) {
                    width: 2.2rem;
                    height: 2.2px;
                 }
            }
            &-text {
                display: none;
                grid-row: 1 / 2;
                grid-column: 1 / 2;
                align-self: center;
                text-align: center;
                transform: translateY(5rem);
                font-size: 1.6rem;
                font-family: $font-display;
                @include respond(phone) {
                    display: none;
                 }
               


            }
      
        }
        
        &-logo {
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;
            width: 15rem;
            height: 15rem;
            @include respond(phone) { 
                height: 12.5rem;
                width: 12.5rem;
            }
            @media only screen and (max-width: 28.6875em) {
                grid-column: 1 / -1;
                justify-self: center;
                align-self: center;
            }
            
        }
        &-accountpage {

            width: 10rem;
            height: 10rem;
            @include respond(phone) { 
                height: 7.5rem;
                width: 7.5rem;
            }
            @media only screen and (max-width: 28.6875em) {
                grid-column: 1 / -1;
                justify-self: center;
                align-self: center;
            }
            
        }
        &-logoaccount {

            width: 15rem;
            height: 15rem;
            @include respond(phone) { 
                height: 12.5rem;
                width: 12.5rem;
            }
            @media only screen and (max-width: 28.6875em) {
                grid-column: 1 / -1;
                justify-self: center;
                align-self: center;
            }
            
        }
        &--selectsize {
            grid-column: 3 / span 1;
            grid-row: 1 / span 1;
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 28.6875em) {
                grid-row: 2 / span 1;
                grid-column: 1 / -1;
                text-align: center;
                
            }
            &-size {
                margin-top: 1rem;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(5rem, 9rem));
                grid-gap: 1.6rem;
                text-transform: uppercase;
                @include respond(phone) { 
                    grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
                    grid-gap: 1rem;
                }
                
            }
        }
        
        

        
    }
    
}
.companylogo {
    max-width:80%;
    //max-height:100%;
    //object-fit: cover;
    &-black {
        background-color: #000;
    }
    &-mavi {
        background-color: rgba(21, 66, 120, 1);
    }
}

.logo-small {
    max-width: 25%;
    @include respond(tab-land-large) {
        max-width: 70%;
    }
    @include respond(tab-port) {
        max-width: 70%;
    }
    @include respond(phone) {
        max-width: 90%;
    }
}
.logo-medium {
    max-width: 50%;
    @include respond(tab-land-large) {
        max-width: 70%;
    }
    @include respond(tab-port) {
        max-width: 70%;
    }
    @include respond(phone) {
        max-width: 90%;
    }
    &-conlanguage {
        @include respond(phone) {
            max-width: 68%;
        }

    }
}
.text {
    &--black {
        color: $color-black;
        
            font-weight: 600;
        
        &-bold {
            color: $color-black;
            font-weight: 600;
    
            &-smaller {
                font-size: 2.8rem;
            }
        }
        &-greybold {
            font-weight: 600;

        }
        &-largebold {
            font-size: 2rem;
            margin: 0 0.5rem;
            

        }
    }
}
.widthhundred {
    width: 100%;
}

//// for fucking added styles
.linkgreen {
    cursor: pointer;
    color: black;
    font-weight: 500;
    &:hover {
        color: #541c8b;
    }
}
.thisisfortoday {
    width: 40rem;
}



.must-container{
    margin-bottom: 1em;
    border: 0.1em solid #073B4C;
}

.must-item{
    position: relative;

    width: 15em;
    height: 1em;

    padding: 0.2em 0;

    border-bottom: 0.1em solid #073B4C;
    background-color: #DAF3FC;
}

.must-text{
    position: absolute;
    z-index: 0;
    top: 0.6em;
    left: 1em;

    width: 100%;

    padding: 0;
    margin: 0;
    line-height: 0;
}

.must-line{
    position: absolute;
    z-index: 1;
    top: 0.5em;

    width: 0;
    height: 0.2em;

    background-color: #EF476F;

    transition: 0.5s;
}

.cross-out{
    width: 100%;
}
//////////////@at-root





.privacypolicy {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}


.webcammobile {
   height: 100vh !important;
   width: 100vw !important;
   overflow: hidden;
/*    @include respond(tab-port) {
       width: 900px !important;
       height: 480px !important;
   } */
    @include respond(phone) {
        object-fit: cover;
       /*  width: unset !important; */
    }
}



.avatar {
    display: flex;
    flex-direction: column;
   
    
    padding: 7.5rem;
    background: #ab7fd8;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #a1c4fd, #ab7fd8);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #a1c4fd, #ab7fd8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
    
    
    @include respond(tab-port) {
        padding: 0;
        box-shadow: none;
        margin: 3rem;
        flex-direction: column;
    }
    @include respond(tab-port-small) {
        margin: 3rem;
    }
    @include respond(phone) {
        margin: 1rem;
    }
    &__wrapper {
        height: 100%;
        width: 100%;
        display: flex;

        

    }
    &__inputs {
        display: grid;
        grid-gap: 2rem;
        &--control {
            display: grid;
            grid-template-columns: 1fr 10rem;
            grid-gap: 1rem;
            @include respond(tab-port) {
                grid-template-columns:1fr;
            }
            @include respond(phone) { 
                grid-template-columns: 1fr;
            }
        }
    }
    &__input {
        &--label {
            color: black;
            font-weight: 500;
            font-size: 1.6rem;
            @include respond(big-desktop) {
                font-size: 1.6rem;
            }
        }
        &--input {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: inherit;
            width: 100%;
            height: auto;
            padding: 0.5rem 1rem;
            border: none;
            outline: none;
            border-radius: 1rem;
            color: #fff;
            text-rendering: optimizeLegibility;
            background: linear-gradient(to right, #b26dfa, #7367a0);
           
        }
    }
    &__overlay {
        width: 180px;
        position: absolute;
        @include absCenter;
    }

}
.none {
    display: none;
}

.bg {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #663796 40%, #a1c4fd 50%);

    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
  }
  
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:4s;
  }
  
  .bg3 {
    animation-duration:5s;
  }
  
  @keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }

.displayflexetc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
        margin-top: 5rem;
    }
}
.lastPage {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #ab7fd8;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #a1c4fd, #ab7fd8);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #a1c4fd, #ab7fd8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.showobj {
    
    display: flex;


    div {
        height: 600px !important;
        z-index: 5;
        @include absCenter;
    }
    @include respond(tab-land-large) {
        

        div {
            position: absolute;
            top: 35%;
            height: 500px !important;
        }
    }
    @include respond(tab-port-small) {
        div {
            left: 75%;
        }
    }
    @include respond(phone) {
        /* flex-direction: column-reverse; */
        flex-direction: column;

        div {
            top: 22%;
            height: 400px !important;
            display: flex;
            justify-content: center;
           /*  width: 300px; */
        }
      
    }
}
.vucutolcugosterdiv {
    position: absolute;
    right: 5%;
    top: 10%;
    display: flex;
    flex-direction: column;
    a {
        margin-top: 3rem;
    }
}

#mobilelogohide {
    height: 13rem;
    @include absCenter;
    @include respond(tab-port) {
        display: none;
    }
    @include respond(phone) {
        display: none !important;
    }
}

#mobilebuttonfast {
    margin-top: 10rem;
    margin-left: 7rem;
    height: 8rem;
    text-transform: none !important;
    @include respond(haha-desktop) {
        margin-left: 0;
        @include absCenter;
        top: 28% !important;
    }
    @include respond(tab-port) {
        top: 10rem;
        position: relative;
    }
    @include respond(phone) {
        margin-left: 2rem;
        margin: 0;
      /*   margin-top: 3rem; */
        margin-bottom: 5rem;
        position: relative !important;
        transform: none;
        top: 0;
        left: 0;
        width: 27rem;
        height: 6rem;
        font-size: 1.6rem;
        align-self: center;
    }
}

.onlymobileinputgrid {
    @include respond(tab-port) {
        grid-template-columns: 2fr;
    }

}


.overlayleft {
    margin-left: 15rem;
    @include respond(phone) {
        position: absolute;
        margin: 0;
        width: 100% !important;
        right: 0 !important;
        margin-bottom: 5rem;
    }
}

#mobilelinkgiydirilmis {
    @include respond(phone) {
        margin-top: 2rem;
        font-size: 1.6rem;
        padding: 1.5rem;
    }
}

#makeblack {
    display: none;
    @include respond(phone) {
        display: block;
        z-index: -1;
        position: absolute;
        background-color: black;
        min-height: 100vh !important;
        width: 100vw !important;
    }
}
#closeevery {
    background-image: none !important;
    opacity: 1 !important;

}


.inputlastpage {
    display: grid !important;
    /* justify-content: unset !important; */
   
    @include respond(phone) {
    
        font-size: 1.4rem;
        padding: 0.5rem 1rem;
    }
}

.objcontainer {
    position: relative;
  
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond(phone) {
        position: absolute;
        top: 45%;
        right: 5%;
    }
}

.avataroutline {
    position: absolute;
    width: 42vh;
    z-index: 9999;
    @include absCenter;
    @include respond(tab-port) {
        width: 200px;
    }
   
    
     @media only screen and (min-device-width: 23.4375em) 
               and (max-device-width: 41.6875em) 
               and (orientation: portrait ) 
               and (-webkit-min-device-pixel-ratio: 2){
                    top: 41%;
                   width: 65vw;
                }
                @media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) {
        width: 80vw;
        top: 41%;
     }
             
    
}

.avatarwidth {
    width: 50rem;
}
.adct {
    grid-template-columns:  1fr 1fr;
}




 .getgenderil {
    font-size: 3rem;
    cursor: pointer;
    color: #5c34ae;
    font-weight: 600;
    text-align: center;
    width: 15rem;
    
    @include respond(tab-port) {
        border: none;
    }
 }

 .loadinglogo {
     position: absolute;
     width: 90rem;
     @include absCenter;
     @include respond(phone) {
         width: 35rem;
     }
 }
 .erroranimation {
     width: 25rem
 }
 .loadtext {
    @include absCenter;
    font-weight: 700;
    font-size: 3rem !important;
    color: #3e1368;

 }

 .avatarinputslastpage {
    border: none;
     background-color: transparent;
     border-bottom: 1px solid black;
 }

 .finalbackground {
     width: 50rem;
     height: 40rem;
     align-self: center;
     position: absolute;
     top: 73%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include respond(tab-land-large) {
        top: 58%;
    }
    @include respond(tab-port-small) {
        top: 55%;
        width: 35rem;
        left: 75%;
    }
    @media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
      top: 49%;
}

    @include respond(phone) {
        width: 20rem;
        height: 15rem;
        top: 43%;
    }
    @media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 
        top: 39%;
    }

 }

 .lastpagelogo {
     width: 500px;
     position: absolute;
     top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include respond(tab-land-large) { 
        top: 10%;
        width: 300px;
    }
    @include respond(tab-port-small) {
        left: 75%;
        width: 250px;
    }
    @include respond(phone) {
        display: none;
    }
 }

 li {
     list-style: none;
 }
 .footer-title-h {
     margin-bottom: 1rem;
     font-size: 2.4rem;
     color: black;
     font-weight: 600;
 }
 .footer-title {
     margin-top: 2rem;
     font-size: 1.8rem;
     color: black;
     font-weight: 500;
     a {
         color: black !important;
         &:hover {
            text-decoration: underline;
         }
         
     }
 }

 .footermobile {
    text-align: end;
    /*  @include respond(tab-land-large) {
         text-align: end;
     } */
     @include respond(tab-port-small) {
         display: none;
     }
 }

 .footeradres {
     @include respond(tab-port) {
         display: none;
     }
 }
.avatarsteps {
    &--wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      
    }
    border-radius: 10px;
    display: flex;
    flex-direction: column;
   
    
    padding: 7.5rem;
    background: #ffffff; 
   
}

.landingmobile {
    background: #ab7fd8; 
    background: -webkit-linear-gradient(to bottom, #ffffff, #b170f3);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to  bottom, #ffffff, #b170f3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: flex;
    justify-content: center;
    align-items: center;

}

.d-none {
    top: 15%;
}

.cameraframe {
    width: 330px;
    @include absCenter;
}
.framesevg {
    @include absCenter;
    top: 41%;
    width: 95vw;
    height: 77vh;
    z-index: 9999;
}

input.my-input:-internal-autofill-selected {
    background-color: rgb(0, 0, 0) !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}

.cameralan {
    background: linear-gradient(to bottom, #ffffff, #b170f3);
}

.languageselect {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 3rem;
    @include respond(tab-port) {
        top: 3rem;        
    }
}

.languageselector {
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
    text-align: center;
    @include respond(tab-port) {
        color: black;
    }
    
    /* & > * {
        &:not(:first-child) {
            margin-left: 2rem;
        }
    } */
    
    &__flex {
        margin-left: 2rem;
        cursor: pointer;


    }
    &__flag {
        border-radius: 25px;
        box-shadow: 0 1rem 5rem rgba(#000, .2);
        &:hover {
            box-shadow: 0 1rem 5rem rgba(#fff, .9);
        }

    }
    &__text {
        margin-top: 2rem;

    }
}

.metricchange {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include respond(tab-port) {
        grid-template-columns: 16rem 10rem 16rem;

    }
    
    font-size: 1.2rem;
    justify-items: center;
    align-items: center;
    &__flex {
        display: flex;
        flex-direction: column;
    }
    &--text {
        &-large {
            color: black;
            font-weight: 600;
            font-size: 1.3rem;
        }
        &-small {
            font-size: 1rem;
            font-weight: 500;
        }
    }
    text-align: center;
}

.last {
    top: 3rem;
    right: 7rem;
}

.cizgi {
    position: absolute;
    top: 44%;
    right: 50%;
    width: 5px;
    height: 26.5rem;
    background: #663796;
}
.mbweb {
    margin-left: 5rem;
    @include respond(tab-port) {
        margin-left: 0;
    }
    
}

.posabsmobil {
    @include respond(phone) {
        display: none;
    }
}

.logocam {
    -webkit-animation: cssAnimation 3s forwards; 
    animation: cssAnimation 3s forwards;

}

@keyframes cssAnimation {
    0%   {opacity: 1;}
    90%  {opacity: 1;}
    100% {opacity: 0;}
}
@-webkit-keyframes cssAnimation {
    0%   {opacity: 1;}
    90%  {opacity: 1;}
    100% {opacity: 0;}
}

#allpagewhite {
    position: absolute;
    background: linear-gradient(to bottom, #ffffff, #b170f3);
    width: 100vw;
    height: 100vh;
    z-index: 99999999;
    display: flex;
    justify-content: center;
}
#bar {
   /*  @include absCenter; */
   top: 20%;
    z-index: 9999999999;
    height: 30vh;
    position: absolute;
   

}
#barupdown {
    top: 20%;
  
   /*  @include absCenter; */
   position: absolute;
    z-index: 9999999999;
    width: 35px;
    height: calc(
        calc(80 * calc(30vh / 150)) -
          calc(70 * calc(30vh / 150))
    );

}

.admin-navbar {
    height: 100px;
    width: 100%;
}

.admin {

    &-navbar {
        height: 60px;
        width: 100%;
        overflow: hidden;
        background-color: #E5C0F5;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
    }

    &-button, &-logo {
        height: 60px;
        float: left;
        display: inline;
        color: #000;
        font-size: 20px;
        text-align: center;
        padding: 14px 20px;
        text-decoration: none;
        background-color: #E5C0F5;
        border: none
    }

    &-button:hover {
        cursor: pointer;
        background-color: #D4B0C4;
    }

    &-button.right {
        float: right;
    }
}
